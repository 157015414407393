import React, { Component } from 'react';
 
import {Button, Breadcrumbs, InputBase, FormLabel, Paper, TableSortLabel, TableHead, TableRow, TableCell,IconButton, Fab, TextField, Snackbar,} from "@material-ui/core";
import { Col, Row } from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import { Redirect } from "react-router-dom";
import AddBordereaux from "./AddBordereaux";
import { connect } from "react-redux";
import {BORDEREAUX_SELECT} from "../../redux/actions/GetBordereau";
import "./re.scss";

import EditerBordereauBancaire from "./EditerBordereauBancaire";
import EditIcon from "@material-ui/icons/Edit";

import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import { Link } from "react-router-dom";
import "./Responsiveregc.css";
import Axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class FicheBordereaux extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
    
      loggedIn,
      rechs:"",
      rechs: [],
      rech:[],
      icon: false,
      rechercheclicked: true,
      tabtab: [],
      defaultdate: date,
      firstdate: date,
      seconddate: date,
      rechdats: [],
      show:false,
      showrechbydate: false,
      order: "asc",
      orderBy: "",
      gilad: true,
      detailAffectation: [],
      addModalShow: false,
      addModalShow1: false,
    };
  }
  componentDidMount() {
    Axios.get(
      `https://www.24-crm.com/webservice/api/BordereauVersements?id= &coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      ).then((res) => {  
      this.setState( { rechs: res.data, rechercheclicked: true });
    });


    this.props.BORDEREAUX_SELECT(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    document.title = "Bordereaux Bancaire";
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
    this.state.gilad
      ? this.setState({ showrechbydate: false })
      : this.setState({ rechercheclicked: false });

  };
///////////////////////////////// API POUR LA RECHERCHE DANS ZONE DE RECHERCHE ////////////////////////////////////////////////////////////////
  rechercheHandler = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/BordereauVersements?id=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };
  toggle = () => this.setState({ modal: !this.state.modal });
///////////////////////////////// API POUR LA RECHERCHE PAR DATE DEBIT DE RECHERCHE ///////////////////////////////////////////////////////////
  firstrechdatHandler = (event) => {
    this.setState({ firstdate: event.target.value });
    fetch(
      `https://www.24-crm.com/webservice/api/BordereauVersements?dd=${event.target.value}&df=${this.state.seconddate}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };
//////////////////////////// API POUR LA RECHERCHE PAR DATE FIN DE RECHERCHE /////////////////////////////////////////////////////////////////////
  secondrechdatHandler = (event) => {
    this.setState({ seconddate: event.target.value });
    fetch(
      `https://www.24-crm.com/webservice/api/BordereauVersements?dd=${this.state.firstdate}&df=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };
  render( ) {
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    {/* let addModalClose1 = () => {
    this.setState({ addModalShow: false })};
    let getModalClose = () => this.setState({ GetBByIdModalShow: true });    */}
    let addModalClose = () => this.setState({ addModalShow: false });
    let addModalClose1 = () => this.setState({ addModalShow1: false });
const {
codccb,
NumBord,
DatOp,
Total,
NbChq,
Rib,
Ref, 
vers, 
CodBanque, 
TitreBord, 
Remettant, 
    } = this.state;
    const { darck } = this.props;
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small" >
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Suivi Banque"
              icon={
                <HomeIcon
                  fontSize="small"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // href="/reglement-client"
            label="Suivi Bordereaux"
            icon={<MoveToInboxIcon fontSize="small" />}
          />
        </Breadcrumbs>
        <br />
        <div>
          <Paper
            elevation={0.5}
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
              // height: "45px",
            }}
          >
            <Row style={{display : "flex" , flexWrap: "nowrap"}}>
           <Col sm={9}>
                <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  onLoad={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.isSearchDate ? (
                  <>
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date début
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.defaultdate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  </>
                ) : (
                  <InputBase
                    //defaultValue="Recherhe"
                    margin="dense"
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />
                )}
              </Col>
              <Col sm={3} style={{ display: "flex", justifyContent: "end" }} >
                {/* className="buttregc" */}
                <Button
                  outline
                  color="primary"
                  onClick={() => this.setState({ addModalShow1: true })}
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                >
                  <AddIcon />
                  Bordereau Bancaire
                </Button>
              </Col>
            </Row>
          </Paper>
        </div>
        <br />
{/*////////////////////////////////////////////////////////////////////// POUR L'AFFICHAGE DE TABLEAU ////////////////////////////////////////////////////////////////// */}
        {this.state.rechercheclicked ? (
          <div className="tabree">
            <table>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                 
                darck={darck}
                
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {              
                stableSort(
                  this.state.rechs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.NumBord}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/BordereauVersements?&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                        {
                          method: "GET",
                          header: {
                            Accept: "application/json",
                            "Content-Type": "application/json",  }, }   )
                        .then((response) => response.json())
                        .then((data) => {
                          this.setState({
                            GetBByIdModalShow:true,
                            codccb: test.codccb,
                            NumBord: test.NumBord,
                            DatOp: test.DatOp,
                            Total: test.Total,
                            NbChq: test.NbChq,
                            Rib: test.Rib,
                            Ref: test.Ref, 
                            vers: test.vers, 
                            CodBanque: test.CodBanque, 
                            TitreBord: test.TitreBord, 
                            Remettant: test.Remettant, }); }); }} 
                             >

                    <td> <span style={{ color: "#8f2d56" }}>{test.NumBord}</span> </td>
                    <td> <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {new Date(test.DatOp).toLocaleDateString("fr", DATE_OPTIONS )}</span></td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}><span>{test.Rib}</span> </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}> <span>{test.Total}</span></td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}><span>{test.NbChq}</span> </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.TitreBord}{" "}</td>
      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                          {test.Ref}{" "}   </td> 
                          
                          
                          
                          <td>                               
                            <Tooltip title="Editer">
                                    <Fab size="small">
                                      <EditIcon
                                        style={{}}
                                        onClick={() => {
                                          this.setState({
                                            NumBord: test.NumBord,
                                            Total: test.Total,
                                            NbChq: test.NbChq,
                                            changeButton: true,
                                            addModalShow: true,
                                          });
                                          //   this.deleteRowMod(i);
                                        }}
                                      />
                                    </Fab>
                                  </Tooltip></td>
                          
                            </tr>  ))}  </tbody>     
                             </table> 
                          </div>  



) : this.state.showrechbydate ? (
  <div className="tabree">
  <table>
    <EnhancedTableHead
      classes={classes}
      order={this.state.order}
      
      orderBy={this.state.orderBy}
      onRequestSort={this.handleRequestSort}
      darck={darck}
    />
    <tbody style={{ maxHeight: "60vh" }}>
      {stableSort(
        this.state.rechdats,
        getComparator(this.state.order, this.state.orderBy)
      ).map((test) => (
        <tr
          style={{ cursor: "pointer" }}
          key={test.NumBord}
          onClick={() => {
            fetch(
              `https://www.24-crm.com/webservice/api/BordereauVersements?&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "GET",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",  }, }   )
              .then((response) => response.json())
              .then((data) => {
                this.setState({
                  GetBByIdModalShow:true,
                  codccb: test.codccb,
                  NumBord: test.NumBord,
                  DatOp: test.DatOp,
                  Total: test.Total,
                  NbChq: test.NbChq,
                  Rib: test.Rib,
                  Ref: test.Ref, 
                  vers: test.vers, 
                  CodBanque: test.CodBanque, 
                  TitreBord: test.TitreBord, 
                  Remettant: test.Remettant,  }); }); }}  >
                    <td> <span style={{ color: "#8f2d56" }}>{test.NumBord}</span> </td>
                    <td> <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {new Date(test.DatOp).toLocaleDateString("fr", DATE_OPTIONS )}</span></td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}><span>{test.Rib}</span> </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}> <span>{test.Total}</span></td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}><span>{test.NbChq}</span> </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.TitreBord}{" "}</td>
      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                          {test.Ref}{" "}   </td>  </tr>  ))}  </tbody>      </table> 
                </div>  
):(
<div className="tabree">
<table striped hover size="sm">
<thead style={{ background: "#B8D0E8", color: "white" }} >
 <tr><th>№ Bordereau</th>
     <th>Date</th>
     <th>Rib</th> 
     <th>Total</th>   
     <th>Nb Chéques</th>
     <th>Titre Bordereau</th>
     <th>Reference</th> </tr>  </thead>
                <tbody>
                  {this.props.Clients.Clients.map((test) => (
                    <tr
   key={test.NumBord}
 
   onClick={() => { this.setState({
  
    NumBord: test.NumBord,
    DatOp: test.DatOp,
    Total: test.Total,
    NbChq: test.NbChq,
    Rib: test.Rib,
    Ref: test.Ref, 
    vers: test.vers, 
    CodBanque: test.CodBanque, 
    TitreBord: test.TitreBord, 
    Remettant: test.Remettant, 
                        });
                      }}
                    >
   <td><span>{test.NumBord}</span></td>
                    <td> <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {new Date(test.DatOp).toLocaleDateString("fr", DATE_OPTIONS )}</span></td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}><span>{test.Rib}</span> </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}> <span>{test.Total}</span></td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}><span>{test.NbChq}</span> </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.TitreBord}{" "}</td>
      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                          {test.Ref}{" "}   </td>

<td>                               <Tooltip title="Editer">
                                    <Fab size="small">
                                      <EditIcon
                                        style={{}}
                                        onClick={() => {
                                          this.setState({
                                            NumBord: test.NumBord,
                                            DatOp: test.DatOp,
                                            Total: test.Total,
                                            NbChq: test.NbChq,
                                            Rib: test.Rib,
                                            Ref: test.Ref, 
                                            vers: test.vers, 
                                            CodBanque: test.CodBanque, 
                                            TitreBord: test.TitreBord, 
                                            Remettant: test.Remettant, 
                                            codccb: test.codccb,

                                            changeButton: true,
                                            addModalShow: true,
                                          });  }} />  </Fab> </Tooltip></td></tr> ))} </tbody> </table> </div> )}
 <AddBordereaux show={this.state.addModalShow1} onHide={addModalClose1} />
{/* <GetBordereauBancaireByIdModal
show={this.state.getBByIdModalShow} onHide={getModalClose}  /> */} 

<EditerBordereauBancaire
          NumBord={this.state.NumBord}
          DatOp={this.state.DatOp}
          Total={this.state.Total}
          NbChq={this.state.NbChq}
          Rib={this.state.Rib}
          Ref={this.state.Ref}
          vers={this.state.vers}
          CodBanque={this.state.CodBanque}
          TitreBord={this.state.TitreBord}
          Remettant={this.state.Remettant}
          codccb={this.state.codccb}
      




          show={this.state.addModalShow}
          onHide={addModalClose}
        />


{/* 
<label>Num bordereaux     :     {this.state.NumBord}  </label><br></br>
<label>Date               :     {this.state.DatOp}    </label><br></br>
<label>Total              :     {this.state.Total}    </label><br></br>
<label>Nombre des chaques :     {this.state.NbChq}    </label><br></br>
<label>Rib :              :     {this.state.Rib}      </label><br></br> */}


      </div>
    );
  }
}

{/*///////////////////////////////////////////////////////////// REDUX ////////////////////////////////////////////////////// */}
function mapStateToProps(state) {
  return {
    bes: state.bes,
    show:state.show,
    rech:state.rech,
    Clients:state.Clients,
    Bordereaux:state.Bordereaux,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,  };}
function mapDispatchToProps(dispatch) {
  return {
    BORDEREAUX_SELECT: (a, b, c, d, e) =>
      dispatch(BORDEREAUX_SELECT(a, b, c, d, e)) };}
export default connect(mapStateToProps, mapDispatchToProps)(FicheBordereaux);
// les fonctions utilisées pour le trie des colonnes
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;}
  if (b[orderBy] > a[orderBy]) {
    return 1; }
  return 0;}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [ 
  {
    id: "NumBord",
    numeric: false,
    disablePadding: true,
    label: "№ Bordereaux",
  },
  {
    id: "dateB",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  { id: "Rib", numeric: false, disablePadding: false, label: "Rib" },
  {
    id: "Total",
    numeric: true,
    disablePadding: false,
    label: "Total",
  },
  { id: "Nb Chq", numeric: true, disablePadding: false, label: "Nb Chéques" },
  {
    id: "Titre",
    numeric: true,
    disbalePadding: false,
    label: "Titre Bordereau",
  },
  {
    id: "Ref",
    numeric: true,
    disbalePadding: false,
    label: "Reference",
  },
];
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
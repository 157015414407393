import { FormHelperText, IconButton, Snackbar } from "@material-ui/core";
import React, { useState, useEffect ,useRef} from "react";

import { Card } from "react-bootstrap";
import { Row, Col, FormGroup } from "reactstrap";
import { TextField, MenuItem, Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import InfoEntreprise from "../../redux/actions/InfoEntreprise";
import { withStyles } from "@material-ui/styles";
import UploadImagedossier from "./UploadImagedossier";
const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const ModifierFicheDossier = (props) => {
  const { darck, classes } = props;
  const [tablecat, settablecat] = useState([
    { code: "0:Assujetti" },
    { code: "1:Non Assujetti" },
    { code: "2:Exonéré TVA/FODEC" },
    { code: "3:Exonéré TVA" },
    { code: "4:Suspenssion" },
    { code: "5:Cession à Quai" },
    { code: "6:Réduction TVA" },
  ]);
  const [categoriefiscale, setcategoriefiscale] = useState("");
  const [code, setCode] = useState("");
  const [Nom, setNom] = useState("");
  const [activity, setActivity] = useState("");
  const [adresse, setAdresse] = useState("");
  const [email, setEmail] = useState("");
  const [ville, setVille] = useState("");
  const [pays, setPays] = useState("");
  const [Cpostal, setCodePostal] = useState("");
  const [tel1, setTel1] = useState("");
  const [tel2, setTel2] = useState("");
  const [fax, setFax] = useState("");
  const [site, setSite] = useState("");
  const [codeTva, setCodeTva] = useState("");
  const [CodeDouane, setCodeDouane] = useState("");
  const [Regcom, setRegCom] = useState("");
  const [cnss, setCnss] = useState("");
  const [banque, setBanque] = useState("");
  const [agence, setAgence] = useState("");
  const [tauxFodec, setTauxFodec] = useState("");
  const [MajTva, setMajTva] = useState("");
  const [timbre, setTimbre] = useState("");
  const [rib1, setRib1] = useState("");
  const [rib2, setRib2] = useState("");
  const [rib3, setRib3] = useState("");
  const [Photo, setPhoto] = useState("");
  const [snackbaropen, setsnackbaropen] = React.useState(false);
  const [snackbarmsg, setsnackbarmsg] = React.useState("");
// const fileInputRef = React.createRef(setPhoto);
// const imageRef = React.createRef(setPhoto);
const fileInputRef = useRef(Photo);
const imageRef = useRef(Photo);

  useEffect(() => {
    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        props.InfoEntreprisefunc(result);
        setCode(result[0].coddos);
        setNom(result[0].lib);
        setActivity(result[0].spe);
        setAdresse(result[0].adr);

        setEmail(result[0].email);
        setVille(result[0].ville);
        setPays(result[0].pays);
        setCodePostal(result[0].cp);
        setTel1(result[0].tel1);
        setTel2(result[0].tel2);
        setFax(result[0].fax);
        setSite(result[0].sitweb);
        setCodeTva(result[0].codtva);
        setCodeDouane(result[0].douane);
        setRegCom(result[0].rc);
        setCnss(result[0].cnss);
        setBanque(result[0].matban);
        setAgence(result[0].agence);
        setTauxFodec(result[0].taufodec);
        setMajTva(result[0].taumajor);
        setTimbre(result[0].valtimbre);
        setcategoriefiscale(result[0].catfisc);
        setRib1(result[0].rib);
        setRib2(result[0].RIB2);
        setRib3(result[0].RIB3);
        setPhoto(result[0].Photo);
      });
  }, []);
  useEffect(() => {
    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());
  });
  const catHandler = (e) => {
    setcategoriefiscale(e.target.value);
  };

  // const enregistrer = () => {
  //   fetch(
  //     `https://www.24-crm.com/webservice/api/Dossier?coddos=${code}&BDsql=${`PS${code.toUpperCase()}`}&lib=${Nom}&spe=${activity}&adr=${adresse}&ville=${ville}&cp=${Cpostal}&pays=${pays}&tel1=${tel1}&tel2=${tel2}&fax=${fax}&email=${email}&sitweb=${site}&douane=${CodeDouane}&codtva=${codeTva}&rc=${Regcom}&cnss=${cnss}&taufodec=${tauxFodec}&taumajor=${MajTva}&valtimbre=${timbre}&catfisc=${categoriefiscale}&matban=${banque}&agence=${agence}&rib=${rib1}&RIB2=${rib2}&RIB3=${rib3}&coddoos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
  //     {
  //       method: "PUT",
  //       headers: { // Utilisez "headers" au lieu de "header"
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //     )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         setsnackbaropen(true);
  //         setsnackbarmsg(result);
  
  //         // Rafraîchir la page seulement en cas de succès de l'appel API
  //        // window.location.reload();
  //       },
  //       (error) => {
  //         setsnackbaropen(true);
  //         setsnackbarmsg("failed");
  //       }
  //     );
  // };
  
  const enregistrer = () => {
    const requestBody = {
      coddos: code,
      BDsql: `PS${code.toUpperCase()}`,
      lib: Nom,
      spe: activity,
      adr: adresse,
      ville: ville,
      cp: Cpostal,
      pays: pays,
      tel1: tel1,
      tel2: tel2,
      fax: fax,
      email: email,
      sitweb: site,
      douane: CodeDouane,
      codtva: codeTva,
      rc: Regcom,
      cnss: cnss,
      taufodec: tauxFodec,
      taumajor: MajTva,
      valtimbre: timbre,
      catfisc: categoriefiscale,
      matban: banque,
      agence: agence,
      rib: rib1,
      RIB2: rib2,
      RIB3: rib3,
      Photo: Photo,
      
    };
    fetch(
      `https://www.24-crm.com/webservice/api/Dossier?coddoos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody), // Ajoutez le corps de la requête ici
      }
      )
      .then((res) => res.json())
      .then(
        (result) => {
          setsnackbaropen(true);
          setsnackbarmsg(result);
  
          // Rafraîchir la page seulement en cas de succès de l'appel API
          window.location.reload();
        },
        (error) => {
          setsnackbaropen(true);
          setsnackbarmsg("failed");
        }
      );
  };

  const snackbarClose = () => {
    setsnackbaropen(false);
  };
  
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //     imageRef.current.src = reader.result; // Update image src
  

  //     };
  //     reader.readAsDataURL(file);

  //   }
 
  // };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result.replace("data:", "").replace(/^.+,/, "")); // Store base64 string in state
      };
      reader.readAsDataURL(file);
    }

 
  };

 const handleFileSelect = (base64String) => {
    setPhoto({ photo: base64String });
  };


  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbaropen}
        autoHideDuration={2000}
        onClose={snackbarClose}
        message={<span id="message-id"> {snackbarmsg} </span>}
        action={[
          <IconButton key="close" color="inherit" onClick={snackbarClose}>
            x
          </IconButton>,
        ]}
      ></Snackbar>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton 
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      color: `${darck ? "#020F64" : "#fff"}`,
                      borderTop: `${darck ? "" : "2px solid #fff"}`,
                      borderRight: `${darck ? "" : "2px solid #fff"}`,
                      borderLeft: `${darck ? "" : "2px solid #fff"}`,
                      borderBottom: `none`,
                    }}>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>Modifier la Fiche Dossier</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
              >
          <div className="container">
            {/* <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            // open={this.state.snackbaropen}
            autoHideDuration={2000}
            onClose={this.snackbarClose}
            message={<span id="message-id"> {this.state.snackbarmsg} </span>}
            action={[
              <IconButton key="close" color="inherit" onClick={this.snackbarClose}>
                x
              </IconButton>,
            ]}
          ></Snackbar> */}

            {/* <form autocomplete="off" 
                  onSubmit={enregistrer} 
                  className={`${darck ? "" : classes.root}`}> */}
{/* 
<form autocomplete="off" method="post" onSubmit="event.preventDefault(); enregistrer();" id="myForm">
    <input type="hidden" name="_method" value="PUT"></input> */}

              <Row>
                <Col sm={6}>
                  <Card className="card0">
                    <Card.Body
                                          style={{
                                            backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                            borderTop: `${darck ? "" : "2px solid #fff"}`,
                                            borderRight: `${darck ? "" : "2px solid #fff"}`,
                                            borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                            borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                            borderRadius: "5px",
                                          }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Code"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="coddos"
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                              disabled
                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                          <img
                                ref={imageRef}
                                // src={Photo}
                                src={`data:image/png;base64,${Photo} `}
                                alt="Pas-Photo"
                                style={{ width: '50px', height: '50px', marginTop: "-5px", marginLeft: "55px ", borderRadius: "5px" }}
                              />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Nom Commercial"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={Nom}
                              onChange={(e) => setNom(e.target.value)}
                              name="nomc"

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="text"
                              label="Activité"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="activ"
                              value={activity}
                              onChange={(e) => setActivity(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Adresse"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="adresse"
                              value={adresse}
                              onChange={(e) => setAdresse(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Email"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={email}
                              name="email"
                              onChange={(e) => setEmail(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-ville"
                              label="Ville"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={ville}
                              onChange={(e) => setVille(e.target.value)}
                              name="ville"

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Pays"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={pays}
                              onChange={(e) => setPays(e.target.value)}
                              name="pays"

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="number"
                              label="Code Postal"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={Cpostal}
                              onChange={(e) => setCodePostal(e.target.value)}
                              name="cp"

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                             // type="number"
                              type="tel"
                              label="Téléphone 1"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={tel1}
                              onChange={(e) => setTel1(e.target.value)}
                              name="tel1"

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Téléphone 2"
                             // type="number"
                              type="tel"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="tel2"
                              value={tel2}
                              onChange={(e) => setTel2(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Fax"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="fax"
                              value={fax}
                              onChange={(e) => setFax(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Site Web"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={site}
                              name="sitewebcli"
                              onChange={(e) => setSite(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      
                     
                      </Row>
                      {/* <br /> */}
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={6}>
                  <Card className="card0">
                    <Card.Body 
                                          style={{
                                            backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                            borderTop: `${darck ? "" : "2px solid #fff"}`,
                                            borderRight: `${darck ? "" : "2px solid #fff"}`,
                                            borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                            borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                            borderRadius: "5px",
                                          }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-TVA"
                              label="Maricule Fiscale"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="codtva"
                              value={codeTva}
                              onChange={(e) => setCodeTva(e.target.value)}
                              required

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-douane"
                              label="Code Douane"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={CodeDouane}
                              name="coddouane"
                              onChange={(e) => setCodeDouane(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-reg"
                              label="Reg.Com"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={Regcom}
                              name="regcom"
                              onChange={(e) => setRegCom(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-cnss"
                              label="CNSS"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="cnss"
                              value={cnss}
                              onChange={(e) => setCnss(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* ////////////////////////////////////////////////// */}
                      <Row form>
                        <Col>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-banque"
                              label="Banque"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="banque"
                              value={banque}
                              onChange={(e) => setBanque(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-agence"
                              label="Agence"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="agence"
                              value={agence}
                              onChange={(e) => setAgence(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Taux Fodec"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="fodec"
                              value={tauxFodec}
                              onChange={(e) => setTauxFodec(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic-maj"
                              label="Majoration TVA"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="maj"
                              value={MajTva}
                              onChange={(e) => setMajTva(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup controlId="catfisc">
                            <TextField
                              // className="card add-input"
                              id="outlined-select-timbre"
                              label="Timbre Fisc"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={timbre}
                              type="number"
                              name="timbrefisc"
                              onChange={(e) => setTimbre(e.target.value)}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}

                                // onChange={this.catHandler}
                            ></TextField>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup controlId="catfisc">
                            <TextField
                              // className="card add-input"
                              id="outlined-select-currency"
                              select
                              label="Categorie Fisc"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="catfisc"
                              required
                              value={categoriefiscale}
                              onChange={catHandler}

                              InputProps={{
                                className: !darck
                                  ? props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            >
                              {tablecat.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.code}
                                </MenuItem>
                              ))}
                            </TextField>

                            {categoriefiscale === "0:Assujetti" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Assujetti{" "}
                              </FormHelperText>
                            ) : categoriefiscale === "1:Non Assujetti" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Non Assujetti{" "}
                              </FormHelperText>
                            ) : categoriefiscale === "2:Exonéré TVA/FODEC" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Exonéré TVA/FODEC{" "}
                              </FormHelperText>
                            ) : categoriefiscale === "3:Exonéré TVA" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Exonéré TVA{" "}
                              </FormHelperText>
                            ) : categoriefiscale === "4:Suspenssion" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Suspenssion{" "}
                              </FormHelperText>
                            ) : categoriefiscale === "5:Cession à Quai" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Cession à Quai{" "}
                              </FormHelperText>
                            ) : categoriefiscale === "6:Réduction TVA" ? (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Réduction TVA{" "}
                              </FormHelperText>
                            ) : (
                              <FormHelperText
                                style={{
                                  fontSize: "12px",

                                  color: "#007bff",
                                }}
                              >
                                Cat Fiscale{" "}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Col>
                        <FormGroup>
                          <TextField
                            // className="card add-input"
                            id="standard-basic-rib1"
                            validateOnBlur
                            type="text"
                            label="RIB1"
                            value={rib1}
                            margin="dense"
                            validate={true}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => setRib1(e.target.value)}
                            variant="outlined"
                            fullWidth
                            name="rib1"

                            InputProps={{
                              inputProps: { minLength: 0, maxLength: 20 },
                              className: !darck
                                ? props.classes.input
                                : null,
                                inputProps: { minLength: 0, maxLength: 20 },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <TextField
                            // className="card add-input"
                            id="standard-basic-rib2"
                            validateOnBlur
                            type="text"
                            label="RIB2"
                            margin="dense"
                            value={rib2}
                            variant="outlined"
                            fullWidth
                            name="rib2"
                            validate={true}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => setRib2(e.target.value)}

                            InputProps={{
                              inputProps: { minLength: 0, maxLength: 20 },
                              className: !darck
                                ? props.classes.input
                                : null,
                                inputProps: { minLength: 0, maxLength: 20 },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <TextField
                            validateOnBlur
                            // className="card add-input"
                            id="standard-basic-rib3"
                            type="text"
                            label="RIB3"
                            value={rib3}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="rib3"
                            validate={true}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => setRib3(e.target.value)}

                            InputProps={{
                              inputProps: { minLength: 0, maxLength: 20 },
                              className: !darck
                                ? props.classes.input
                                : null,
                                inputProps: { minLength: 0, maxLength: 20 },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={2}>
                              {/* <img
                                ref={imageRef}
                                src={setPhoto}
                                alt="Pas-Photo"
                                style={{ width: '80px', height: '80px', marginTop: "-75px", marginLeft: "65px" }}
                              /> */}
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                      
                                // style={{ display: 'none' }} // Hide the file input
                              />
                             
                            </Col>
                            <br></br>
                    </Card.Body>
                  </Card>
                </Col>

                
              </Row>
              <br></br>
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                   <Button
                   type="submit"
        style={{ width: "100%" }}
        variant="contained"
        color="primary"
        onClick={enregistrer} // Appeler la fonction enregistrer lors du clic
      >
        Enregistrer
      </Button>
                </Col>
              </Row>
             {/* </form>  */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    InfoEntreprisefunc: (a) => dispatch(InfoEntreprise(a)),
  };
}

function mapStateToProps(state) {
  return {
    InfoDossier: state.InfoDossier,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierFicheDossier));

import React, { Component } from "react";
import moment from 'moment';
import {
  Button,
  Breadcrumbs,
  InputBase,
  FormLabel,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Col, Row } from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import { Redirect } from "react-router-dom";
import AddReModal from "./AddReglementModal";
import { connect } from "react-redux";
import { SelectReglementFournisseur } from "../../redux/actions/GetReglementFournisseur";
import "./re.scss";
import GetREByIdModal from "./GetReglementByIdModal";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import { Link } from "react-router-dom";
import "./Responsiveregf.css";
import translate from "../../i18n/translate";

import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import * as XLSX from 'xlsx';
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";

const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class ReglementFournisseur extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      addModalShow: false,
      getREByIdModalShow: false,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      tabtab: [],

      defaultdate: date,
      firstdate: date,
      seconddate: date,
      rechdats: [],
      showrechbydate: false,
      order: "asc",
      orderBy: "",
      gilad: true,
      detailAffectation: [],
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    this.props.SelectReglementFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    document.title = "Réglement Fournisseur";
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });

    this.state.gilad
      ? this.setState({ showrechbydate: false })
      : this.setState({ rechercheclicked: false });
  };

  rechercheHandler = (event) => {
    this.setState({rechs: []});
    fetch(
      `https://www.24-crm.com/webservice/api/REGFRs?id=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };

  toggle = () => this.setState({ modal: !this.state.modal });

  firstrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ firstdate: event.target.value });

    fetch(
      `https://www.24-crm.com/webservice/api/REGFRs?datt=${event.target.value}&dattt=${this.state.seconddate}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };

  secondrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ seconddate: event.target.value });

    fetch(
      `https://www.24-crm.com/webservice/api/REGFRs?datt=${this.state.firstdate}&dattt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  
  getTotalmontant = () => {
    let totalmontant = 0;
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
      
          totalmontant += parseFloat(Number(article.monreg));
    })
  }else if(this.state.showrechbydate){
    this.state.rechdats.forEach((article)=>{
      totalmontant+=parseFloat(Number(article.monreg));
    })
  }
  else{
    this.props.rf.rf.forEach((article) => {
      totalmontant += parseFloat(Number(article.monreg));
    })
  };
    return totalmontant.toFixed(3); // arrondi à 4 chiffres après la virgule
  };
exportToExcel = () => {

  if(this.state.rechercheclicked){
  // Préparation des données
  const data = this.state.rechs.map(item => {
    const date = new Date(item.datreg);
    const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
    const piece = item.typach;
    const piece1 = item.pj;
    const pj = `${piece}--${piece1}`;
    return [
      item.numreg,
      formattedDate,
      item.codfrs,
      item.raisoc,
      item.monreg,
      item.modreg,
      item.VENDEUR,
    ];
  });

  // Calcul des totaux
  const totals = data.reduce(
    (acc, row) => {
      acc[4] += row[4]; // Montant Reg
      return acc;
    },
    ['', 'T o t a u x', '', '', 0, '','']
  );

  // Ajout des totaux à la fin des données
  data.push(totals);

  // Création d'un nouveau classeur
  const classeur = XLSX.utils.book_new();
  const feuille = XLSX.utils.aoa_to_sheet([]);

  // Ajout du titre de tableau (première ligne)
  const title = 'Liste des Regelements fournisseurs';
  feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
  feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]; // Fusionner les cellules pour le titre

  // Définition de l'entête du tableau (deuxième ligne)
  const header = ['№ REG', 'Date', 'Code fournisseurs', 'Raison sociale', 'Montant Reg', 'Mode Réglement', 'Utilisateur'];
  XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

  // Ajout des données de tableau à partir de la cinquième ligne
  XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

  // Définition des bordures et styles des cellules
  const range = XLSX.utils.decode_range(feuille['!ref']);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!feuille[cellRef]) continue;
      feuille[cellRef].s = {
        border: {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' }
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center'
        },
        fill: R === range.e.r ? { fgColor: { rgb: 'DDEBF7' } } : null, // Color the totals row
      };
    }
  }

  // Fusion de la cellule pour le label "Totaux"
  const lastRow = range.e.r + 1; // Adjust for 0-index
  feuille[`A${lastRow + 1}`] = { v: 'Totaux', t: 's' }; // Add the 'Totaux' label
  feuille['!merges'].push({ s: { r: lastRow, c: 0 }, e: { r: lastRow, c: 3 } });

  // Ajout de la feuille au classeur
  XLSX.utils.book_append_sheet(classeur, feuille, "REGFRS");

  // Génération d'un fichier téléchargeable
  XLSX.writeFile(classeur, "REGFRS.xlsx");
}else if(this.state.showrechbydate){
 // Préparation des données
 const data = this.state.rechdats.map(item => {
  const date = new Date(item.datreg);
  const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
  const piece = item.typach;
  const piece1 = item.pj;
  const pj = `${piece}--${piece1}`;
  return [
    item.numreg,
    formattedDate,
    item.codfrs,
    item.raisoc,
    item.monreg,
    item.modreg,
    item.VENDEUR,
  ];
});

// Calcul des totaux
const totals = data.reduce(
  (acc, row) => {
    acc[4] += row[4]; // Montant Reg
    return acc;
  },
  ['', 'T o t a u x', '', '', 0, '','']
);

// Ajout des totaux à la fin des données
data.push(totals);

// Création d'un nouveau classeur
const classeur = XLSX.utils.book_new();
const feuille = XLSX.utils.aoa_to_sheet([]);

// Ajout du titre de tableau (première ligne)
const title = 'Liste des Regelements fournisseurs'+
` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;

feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]; // Fusionner les cellules pour le titre

// Définition de l'entête du tableau (deuxième ligne)
const header = ['№ REG', 'Date', 'Code fournisseurs', 'Raison sociale', 'Montant Reg', 'Mode Réglement', 'Utilisateur'];
XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

// Ajout des données de tableau à partir de la cinquième ligne
XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

// Définition des bordures et styles des cellules
const range = XLSX.utils.decode_range(feuille['!ref']);
for (let R = range.s.r; R <= range.e.r; ++R) {
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = { c: C, r: R };
    const cellRef = XLSX.utils.encode_cell(cellAddress);
    if (!feuille[cellRef]) continue;
    feuille[cellRef].s = {
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center'
      },
      fill: R === range.e.r ? { fgColor: { rgb: 'DDEBF7' } } : null, // Color the totals row
    };
  }
}

// Fusion de la cellule pour le label "Totaux"
const lastRow = range.e.r + 1; // Adjust for 0-index
feuille[`A${lastRow + 1}`] = { v: 'Totaux', t: 's' }; // Add the 'Totaux' label
feuille['!merges'].push({ s: { r: lastRow, c: 0 }, e: { r: lastRow, c: 3 } });

// Ajout de la feuille au classeur
XLSX.utils.book_append_sheet(classeur, feuille, "REGFRS");

// Génération d'un fichier téléchargeable
XLSX.writeFile(classeur, "REGFRS.xlsx");
}else{
 // Préparation des données
 const data = this.props.rf.rf.map(item => {
  const date = new Date(item.datreg);
  const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
  const piece = item.typach;
  const piece1 = item.pj;
  const pj = `${piece}--${piece1}`;
  return [
    item.numreg,
    formattedDate,
    item.codfrs,
    item.raisoc,
    item.monreg,
    item.modreg,
    item.VENDEUR,
  ];
});

// Calcul des totaux
const totals = data.reduce(
  (acc, row) => {
    acc[4] += row[4]; // Montant Reg
    return acc;
  },
  ['', 'T o t a u x', '', '', 0, '','']
);

// Ajout des totaux à la fin des données
data.push(totals);

// Création d'un nouveau classeur
const classeur = XLSX.utils.book_new();
const feuille = XLSX.utils.aoa_to_sheet([]);

// Ajout du titre de tableau (première ligne)
const title = 'Liste des Regelements fournisseurs';
feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]; // Fusionner les cellules pour le titre

// Définition de l'entête du tableau (deuxième ligne)
const header = ['№ REG', 'Date', 'Code fournisseurs', 'Raison sociale', 'Montant Reg', 'Mode Réglement', 'Utilisateur'];
XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

// Ajout des données de tableau à partir de la cinquième ligne
XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

// Définition des bordures et styles des cellules
const range = XLSX.utils.decode_range(feuille['!ref']);
for (let R = range.s.r; R <= range.e.r; ++R) {
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = { c: C, r: R };
    const cellRef = XLSX.utils.encode_cell(cellAddress);
    if (!feuille[cellRef]) continue;
    feuille[cellRef].s = {
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center'
      },
      fill: R === range.e.r ? { fgColor: { rgb: 'DDEBF7' } } : null, // Color the totals row
    };
  }
}

// Fusion de la cellule pour le label "Totaux"
const lastRow = range.e.r + 1; // Adjust for 0-index
feuille[`A${lastRow + 1}`] = { v: 'Totaux', t: 's' }; // Add the 'Totaux' label
feuille['!merges'].push({ s: { r: lastRow, c: 0 }, e: { r: lastRow, c: 3 } });

// Ajout de la feuille au classeur
XLSX.utils.book_append_sheet(classeur, feuille, "REGFRS");

// Génération d'un fichier téléchargeable
XLSX.writeFile(classeur, "REGFRS.xlsx");
};
  
}
  render() {
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }

    let addModalClose1 = () => {
      this.setState({ addModalShow: false });
      // window.location.reload();
    };
    let getModalClose = () => this.setState({ getREByIdModalShow: false });

    const {
      regid,
      datereg,
      fournisseur,
      raisonsociale,
      modreg,
      numcais,
      numchq,
      titulaire,
      datech,
      mntreg,
      bqescompte,
      codccb,
      agence,
      monreg,
      verser,
      matban,
      note,
      vendeur,
    } = this.state;
    const { darck } = this.props;
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <StyledBreadcrumb
            component="a"
            href="/"
            label="Suivi Banque"
            icon={
              <HomeIcon
                fontSize="small"
                style={{ color: `${darck ? "" : "#fff"}` }}
              />
            }
            style={{
              color: `${darck ? "" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: "2px solid #fff",
            }}
          />
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Stock"
              icon={
                <HomeIcon
                  fontSize="small"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            href="/reglement-fournisseur"
            label="Règlement Fournisseur"
            icon={<MoveToInboxIcon fontSize="small" />}
          />

<StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
            // href="/fiche-article"
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          />
        </Breadcrumbs>
        {/* <div className="bl-icon">
          <i className="fab fa-dashcube" style={{ fontSize: "25px" }}>
            {" "}
            Règlement Fournisseur
          </i>
        </div> */}
        <br />
        <div>
          <Paper
           // elevation={0.5}
            elevation={2}
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
           
            }}
          >
            <Row style={{display : "flex" , flexWrap: "nowrap"}}>
              {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}

              <Col md={10}>
                <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.isSearchDate ? (
                  <>
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date début
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.defaultdate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  </>
                ) : (
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />
                )}
              </Col>
              <Col md={2}  style={{ display: "flex", justifyContent: "end" }}>
                {/* className="buttregf" */}
                <Button
                  color="primary"
                  onClick={() => this.setState({ addModalShow: true })}
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                >
                  <AddIcon />
                  Règlement Fourniseur
                </Button>
                {/* <InputGroupAddon addonType="append">
              
            </InputGroupAddon> */}
              </Col>
            </Row>
          </Paper>
        
        </div>
        <br />
        {this.state.rechercheclicked ? (
          <div className="tabref">
            <table>
              {/* <thead>
                <tr>
                  <th>№ REG</th>
                  <th>Date</th>
                  <th>Code fournisseur</th>
                  <th >Raison Sociale</th>
                  <th>Montant</th>
                  <th>Solde</th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.numreg}
                    onClick={() =>
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigREGFRS?numregsss=${test.numreg}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                        {
                          method: "POST",
                          header: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then((response) => response.json())
                        .then((data) => {
                          this.setState({
                            getREByIdModalShow: true,
                            regid: test.numreg,
                            datereg: test.datreg,
                            fournisseur: test.codfrs,
                            raisonsociale: test.raisoc,
                            modreg: test.modreg,
                            numcais: test.numcais,
                            numchq: test.numchq,
                            titulaire: test.titulaire,
                            datech: test.datech,
                            mntreg: test.mntreg,
                            bqescompte: test.BqEscompte,
                            codccb: test.codccb,
                            agence: test.agence,
                            monreg: test.monreg,
                            verser: test.verser,
                            matban: test.matban,
                            note: test.lib_reg,
                            vendeur: test.VENDEUR,
                            detailAffectation: data,
                          });
                          fetch(
                            `https://www.24-crm.com/webservice/api/fournisseurs?codfrss=${test.codfrs}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                          )
                            .then((response) => response.json())
                            .then((data) =>
                              this.setState({
                                adresse: data[0].adr,
                                matricule: data[0].CodeTVA.split("/")[0],
                                codtva: data[0].CodeTVA.split("/")[1],
                                categorie: data[0].CodeTVA.split("/")[2],
                                ntab: data[0].CodeTVA.split("/")[3],
                              })
                            );
                        })
                    }
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numreg}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>
                        {new Date(test.datreg).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codfrs}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td>
                      <span style={{ color: "green" }}>
                        {" "}
                        {Number(test.monreg).toFixed(3)}{" "}
                      </span>
                    </td>
                    {/* <td>
                    <span>{Number(test.Reste).toFixed(3)}</span>
                  </td> */}
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.modreg}{" "}
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.VENDEUR}{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>


{/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
<div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Maricule Fiscale : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Règlement fournisseur</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("№ REG")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate(" Date")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Code")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Raison sociale")}</th> 
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("N°Piéce")}</th> 
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Montant")}</th>
              
               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.state.rechs.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    //textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// n.dv ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.numreg}
                
                </td>
 {/* ///////////////////////////////////////////// date ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
          { moment(t.datfac).format("DD/MM/YYYY")} 
                
                </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.codfrs}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// raison ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.raisoc}</span>
                   
            </td>
{/* //////////////////////////////////////////////// N°piece ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
          <span >{t.modreg}</span>
     
</td>
            
{/* //////////////////////////////////////////////// montant ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
            <span>{Number(t.monreg).toFixed(3)}</span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "760px" }}>
          
          
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>
{/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
   
          </div>
        ) : this.state.showrechbydate ? (
          <div className="tabref">
            <table>
              {/* <thead>
                <tr>
                  <th>№ REG</th>
                  <th>Date</th>
                  <th>Code fournisseur</th>
                  <th >Raison Sociale</th>
                  <th>Montant</th>
                  <th>Solde</th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechdats,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.numreg}
                    onClick={() =>
                      fetch(
                        `https://www.24-crm.com/webservice/api/LIGREGFRS?numregsss=${test.numreg}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                        {
                          method: "POST",
                          header: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then((response) => response.json())
                        .then((data) => {
                          this.setState({
                            getREByIdModalShow: true,
                            regid: test.numreg,
                            datereg: test.datreg,
                            fournisseur: test.codfrs,
                            raisonsociale: test.raisoc,
                            modreg: test.modreg,
                            numcais: test.numcais,
                            numchq: test.numchq,
                            titulaire: test.titulaire,
                            datech: test.datech,
                            mntreg: test.mntreg,
                            bqescompte: test.BqEscompte,
                            codccb: test.codccb,
                            agence: test.agence,
                            monreg: test.monreg,
                            verser: test.verser,
                            matban: test.matban,
                            note: test.lib_reg,
                            vendeur: test.VENDEUR,
                            detailAffectation: data,
                          });
                          fetch(
                            `https://www.24-crm.com/webservice/api/fournisseurs?codfrss=${test.codfrs}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                          )
                            .then((response) => response.json())
                            .then((data) =>
                              this.setState({
                                adresse: data[0].adr,
                                matricule: data[0].CodeTVA.split("/")[0],
                                codtva: data[0].CodeTVA.split("/")[1],
                                categorie: data[0].CodeTVA.split("/")[2],
                                ntab: data[0].CodeTVA.split("/")[3],
                              })
                            );
                        })
                    }
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numreg}</span>
                    </td>

                    <td>
                      <span>
                        {new Date(test.datreg).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codfrs}</span>
                    </td>

                    <td>
                      <span>{test.raisoc}</span>
                    </td>
                    <td>
                      <span style={{ color: "green" }}>
                        {" "}
                        {Number(test.monreg).toFixed(3)}{" "}
                      </span>
                    </td>
                    {/* <td>
                      <span>{Number(test.Reste).toFixed(3)}</span>
                    </td> */}
                    <td> {test.modreg} </td>
                    <td> {test.VENDEUR} </td>
                  </tr>
                ))}
              </tbody>
            </table>

 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
<div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Maricule Fiscale : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Règlement fournisseur</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "420px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
             de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
             { moment(this.state.seconddate).format("DD/MM/YYYY")}
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("№ REG")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate(" Date")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Code")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Raison sociale")}</th> 
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("N°Piéce")}</th> 
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Montant")}</th>
              
               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.state.rechdats.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    //textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// n.dv ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.numreg}
                
                </td>
 {/* ///////////////////////////////////////////// date ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
          { moment(t.datfac).format("DD/MM/YYYY")} 
                
                </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.codfrs}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// raison ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.raisoc}</span>
                   
            </td>
{/* //////////////////////////////////////////////// N°piece ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
          <span >{t.modreg}</span>
     
</td>
            
{/* //////////////////////////////////////////////// montant ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
            <span>{Number(t.monreg).toFixed(3)}</span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "780px" }}>
          
          
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>
{/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
   
          </div>
        ) : (
          <div className="tabref">
            <table>
              {/* <thead>
                <tr>
                  <th>№ REG</th>
                  <th>Date</th>
                  <th>Code fournisseur</th>
                  <th >Raison Sociale</th>
                  <th>Montant</th>

                  <th>Solde</th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.props.rf.rf,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.numreg}
                    onClick={() =>
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigREGFRS?numregsss=${test.numreg}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                        {
                          method: "POST",
                          header: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then((response) => response.json())
                        .then((data) => {
                          this.setState({
                            getREByIdModalShow: true,
                            regid: test.numreg,
                            datereg: test.datreg,
                            fournisseur: test.codfrs,
                            raisonsociale: test.raisoc,
                            modreg: test.modreg,
                            numcais: test.numcais,
                            numchq: test.numchq,
                            titulaire: test.titulaire,
                            datech: test.datech,
                            mntreg: test.mntreg,
                            bqescompte: test.BqEscompte,
                            codccb: test.codccb,
                            agence: test.agence,
                            monreg: test.monreg,
                            verser: test.verser,
                            matban: test.matban,
                            note: test.lib_reg,
                            vendeur: test.VENDEUR,
                            detailAffectation: data,
                          });
                          fetch(
                            `https://www.24-crm.com/webservice/api/fournisseurs?codfrss=${test.codfrs}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                          )
                            .then((response) => response.json())
                            .then((data) =>
                              this.setState({
                                adresse: data[0].adr,
                                matricule: data[0].CodeTVA.split("/")[0],
                                codtva: data[0].CodeTVA.split("/")[1],
                                categorie: data[0].CodeTVA.split("/")[2],
                                ntab: data[0].CodeTVA.split("/")[3],
                              })
                            );
                        })
                    }
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numreg}</span>
                    </td>

                    <td>
                      <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {new Date(test.datreg).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codfrs}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td>
                      <span style={{ color: "green" }}>
                        {" "}
                        {Number(test.monreg).toFixed(3)}{" "}
                      </span>
                    </td>
                    {/* <td>
                      <span>{Number(test.Reste).toFixed(3)}</span>
                    </td> */}
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.modreg}{" "}
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {" "}
                      {test.VENDEUR}{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
<div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Maricule Fiscale : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.state.vendeur}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Règlement fournisseur</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("№ REG")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate(" Date")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Code")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Raison sociale")}</th> 
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("N°Piéce")}</th> 
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Montant")}</th>
              
               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.props.rf.rf.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    //textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// n.dv ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.numreg}
                
                </td>
 {/* ///////////////////////////////////////////// date ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
          { moment(t.datfac).format("DD/MM/YYYY")} 
                
                </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.codfrs}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// raison ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.raisoc}</span>
                   
            </td>
{/* //////////////////////////////////////////////// N°piece ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
          <span >{t.modreg}</span>
     
</td>
            
{/* //////////////////////////////////////////////// montant ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
            <span>{Number(t.monreg).toFixed(3)}</span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "760px" }}>
          
          
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>
{/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
    
          </div>
        )}
    <br />
   
   <Paper
     style={{
       backgroundColor: `${darck ? "" : "#172431"}`,
       border: `${darck ? "" : "2px solid #fff"}`,
     }}
     // elevation={0.5}
     elevation={3}
   >
     <Row style={{ flexWrap: "nowrap" }}>
       {/* <InputGroupText>
       <SearchIcon className="search-icon" color="primary" />
     </InputGroupText> */}

       <Col sm={7}>
         <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
          

           {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
           <label
            style={{ color: `${darck ? "#0D47A1" : "#fff"}`
            , fontSize: "12px" }}

           >T O T A U X : </label>
         </div>
       </Col> 
       {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
       <Col sm={1} style={{ display: "flex", justifyContent: "end" }}>
       <label>

    <span style={{ color: "green" }}>
    {this.getTotalmontant()}
</span>
</label>   

         {/* <InputGroupAddon addonType="append">
       
     </InputGroupAddon> */}
       </Col>
     </Row>
   </Paper>
        
{/* /////////////////////////////////////////////////////////// botton print ////////////////////////////////////////////////////////////// */}
<Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
   </Row>  
{/* /////////////////////////////////////////////////////////// botton  print etat ////////////////////////////////////////////////////////////// */}
  
        <AddReModal show={this.state.addModalShow} onHide={addModalClose1} />
        <GetREByIdModal
          adresse={this.state.adresse}
          matricule={this.state.matricule}
          codtva={this.state.codtva}
          categorie={this.state.categorie}
          ntab={this.state.ntab}
          show={this.state.getREByIdModalShow}
          onHide={getModalClose}
          regid={regid}
          datereg={datereg}
          fournisseur={fournisseur}
          raisonsociale={raisonsociale}
          modreg={modreg}
          numcais={numcais}
          numchq={numchq}
          titulaire={titulaire}
          datech={datech}
          vendeur={vendeur}
          mntreg={mntreg}
          bqescompte={bqescompte}
          codccb={codccb}
          agence={agence}
          monreg={monreg}
          verser={verser}
          matban={matban}
          note={note}
          detailAffectation={this.state.detailAffectation}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectReglementFournisseur: (a, b, c, d, e) =>
      dispatch(SelectReglementFournisseur(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    rf: state.rf,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReglementFournisseur);
// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "numreg",
    numeric: false,
    disablePadding: true,
    label: "№ REG",
  },

  {
    id: "datreg",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },

  {
    id: "codfrs",
    numeric: false,
    disablePadding: false,
    label: "Code fournisseur",
  },

  {
    id: "raisoc",
    numeric: true,
    disablePadding: false,
    label: "Raison Sociale",
  },
  { id: "mntreg", numeric: true, disablePadding: false, label: "Montant" },
  // { id: "Reste", numeric: true, disablePadding: false, label: "Solde" },
  {
    id: "modReg",
    numeric: true,
    disbalePadding: false,
    label: "Mode Réglement",
  },
  {
    id: "vendeur",
    numeric: true,
    disbalePadding: false,
    label: "Utilisateur",
  },
];
function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

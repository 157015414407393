import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import AddDevis from "./FieldArraysFormClass";
import { connect } from "react-redux";
import { SelectUser } from "../../redux/actions/DevisClient";
import "../styling/Styles.css";
import EditDevisClientModal from "./EditDevisClientModal";
import "./ss.scss";
import { Redirect } from "react-router-dom";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import "./Responsivedv.css";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { Link } from "react-router-dom";
import {
  Breadcrumbs,
  Paper,
  InputBase,
  Button,
  FormLabel,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
import DateRangeIcon from "@material-ui/icons/DateRange";
import translate from "../../i18n/translate";
import { string } from "prop-types";
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";

import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import * as XLSX from 'xlsx';

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import moment from 'moment';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];



const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class DevisClient extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      editModalShow: false,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      tabtab: [],
      defaultdate: date,
      firstdate: date,
      seconddate: date,
      rechdats: [],
      showrechbydate: false,
      isSearchDate: false,
      addModalShow1: false,
      order: "asc",
      orderBy: "",

      gilad: false,
    };
  }

  componentDidMount() {
    this.props.SelectUser(
       this.props.Auth.username,
       this.props.Auth.fct,
       this.props.ConfigDossier.coddos,
       this.props.ConfigDossier.serveur,
       this.props.ConfigDossier.utilisateur,
       this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectValTimbre(
      this.props.ConfigDossier.database,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur
    );
    document.title = "Devis";
  }

  handleChange = () => {
    // this.setState({ [name]: event.target.checked });
    this.setState({
      isSearchDate: !this.state.isSearchDate,
    });
    // this.state.gilad
    //   ? this.setState({ showrechbydate: false })
    //   : this.setState({ rechercheclicked: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggle = () => this.setState({ modal: !this.state.modal });

  rechercheHandler = (event) => {
    this.setState({rechs: []});
    fetch(
      `https://www.24-crm.com/webservice/api/BCDVCLIs/${event.target.value}?type=DV&vendeur=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };

  firstrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ firstdate: event.target.value });

    fetch(
      
      `https://www.24-crm.com/webservice/api/BCDVCLIs?datt=${event.target.value}&dattt=${this.state.seconddate}&typpe=DV&vendeur=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };

  secondrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ seconddate: event.target.value });

    fetch(
      `https://www.24-crm.com/webservice/api/BCDVCLIs?datt=${this.state.firstdate}&dattt=${event.target.value}&typpe=DV&vendeur=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };

  editModalClose = () => this.setState({ editModalShow: false });
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  // getTotalmontant = () => {
  //   let totalmontant = 0;
  //   this.props.devis.devis.forEach((article) => {
  //     totalmontant += parseFloat(Number(article.mntbon));
  //   });
  //   return totalmontant.toFixed(3); // arrondi à 4 chiffres après la virgule
  // }; 

  getTotalmontant = () => {
    let totalmontant = 0;
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
       
          totalmontant += parseFloat(Number(article.mntbon));
    })
  }
  else if (this.state.showrechbydate){
    this.state.rechdats.forEach((article)=>{
      totalmontant+=parseFloat(Number(article.mntbon));
    })
  }
  else{
    this.props.devis.devis.forEach((article) => {
      totalmontant += parseFloat(Number(article.mntbon));
    })
  };
    return totalmontant.toFixed(3); // arrondi à 4 chiffres après la virgule
  };
exportToExcel = () => {
    if(this.state.rechercheclicked){
   // Préparation des données
   const data = this.state.rechs.map(item => {
    const date = new Date(item.datfac);
    const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
      
    return [ 
      item.numfac,
      formattedDate,
      item.codcli,
      item.raisoc,     
      item.annuler,
      item.mntbon,
      item.vendeur,

      // Ajoutez d'autres champs au besoin
    ];
  });


// Calcul des totaux
const totals = data.reduce(
  (acc, row) => {
 
    acc[5] += row[5]; // mntbon
 
    return acc;
  },

  ['', '', 'T o t a u x', '', '', 0, '']
);

// Ajout des totaux à la fin des données
data.push(totals);
  // Création d'un nouveau classeur
  const classeur = XLSX.utils.book_new();
  const feuille = XLSX.utils.aoa_to_sheet(data);

  // Ajout du titre de tableau (première ligne)
  const title = '                                        Liste des Devis';
  feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
  feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 6 }  }]; // Fusionner les cellules pour le titre


  // Définition de l'entête du tableau (deuxième ligne)
  const header = ['N° DV', 'Date', 'Code client', 'Raison social', 'Etat', 'Montant', 'Utilisateur'];
  XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

  // Ajout des données de tableau à partir de la cinquième ligne
  XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

  const range = XLSX.utils.decode_range(feuille['!ref']);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!feuille[cellRef]) continue;
      feuille[cellRef].s = { alignment: { horizontal: 'center', vertical: 'center' } };
    }
  }



  // Ajout de la feuille au classeur
  XLSX.utils.book_append_sheet(classeur, feuille, "DV");

  // Génération d'un fichier téléchargeable
  XLSX.writeFile(classeur, "DV.xlsx");
  }
  else if (this.state.showrechbydate){
   // Préparation des données
   const data = this.state.rechdats.map(item => {
    const date = new Date(item.datfac);
    const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
      
    return [ 
      item.numfac,
      formattedDate,
      item.codcli,
      item.raisoc,     
      item.annuler,
      item.mntbon,
      item.vendeur,

      // Ajoutez d'autres champs au besoin
    ];
  });


// Calcul des totaux
const totals = data.reduce(
  (acc, row) => {
 
    acc[5] += row[5]; // mntbon
 
    return acc;
  },

  ['', '', 'T o t a u x', '', '', 0, '']
);

// Ajout des totaux à la fin des données
data.push(totals);
  // Création d'un nouveau classeur
  const classeur = XLSX.utils.book_new();
  const feuille = XLSX.utils.aoa_to_sheet(data);

  // Ajout du titre de tableau (première ligne)
  const title = '                                               Liste des Devis'+
  ` de (${ moment(this.state.firstdate).format("DD/MM/YYYY")}) Au  ( ${ moment(this.state.seconddate).format("DD/MM/YYYY")})  `;
  feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
  feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 6 }  }]; // Fusionner les cellules pour le titre


  // Définition de l'entête du tableau (deuxième ligne)
  const header = ['N° DV', 'Date', 'Code client', 'Raison social', 'Etat', 'Montant', 'Utilisateur'];
  XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

  // Ajout des données de tableau à partir de la cinquième ligne
  XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

  const range = XLSX.utils.decode_range(feuille['!ref']);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!feuille[cellRef]) continue;
      feuille[cellRef].s = { alignment: { horizontal: 'center', vertical: 'center' } };
    }
  }



  // Ajout de la feuille au classeur
  XLSX.utils.book_append_sheet(classeur, feuille, "DV");

  // Génération d'un fichier téléchargeable
  XLSX.writeFile(classeur, "DV.xlsx");
  }
  else{
     // Préparation des données
  const data = this.props.devis.devis.map(item => {
    const date = new Date(item.datfac);
    const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
      
    return [ 
      item.numfac,
      formattedDate,
      item.codcli,
      item.raisoc,     
      item.annuler,
      item.mntbon,
      item.vendeur,

      // Ajoutez d'autres champs au besoin
    ];
  });


// Calcul des totaux
const totals = data.reduce(
  (acc, row) => {
 
    acc[5] += row[5]; // mntbon
 
    return acc;
  },

  ['', '', 'T o t a u x', '', '', 0, '']
);

// Ajout des totaux à la fin des données
data.push(totals);
  // Création d'un nouveau classeur
  const classeur = XLSX.utils.book_new();
  const feuille = XLSX.utils.aoa_to_sheet(data);

  // Ajout du titre de tableau (première ligne)
  const title = 'Liste des Devis';
  feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
  feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 6 }  }]; // Fusionner les cellules pour le titre


  // Définition de l'entête du tableau (deuxième ligne)
  const header = ['N° DV', 'Date', 'Code client', 'Raison social', 'Etat', 'Montant', 'Utilisateur'];
  XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

  // Ajout des données de tableau à partir de la cinquième ligne
  XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

  const range = XLSX.utils.decode_range(feuille['!ref']);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!feuille[cellRef]) continue;
      feuille[cellRef].s = { alignment: { horizontal: 'center', vertical: 'center' } };
    }
  }



  // Ajout de la feuille au classeur
  XLSX.utils.book_append_sheet(classeur, feuille, "DV");

  // Génération d'un fichier téléchargeable
  XLSX.writeFile(classeur, "DV.xlsx");
   
  };
  

}

  render() {
    const { darck } = this.props;
    let addModalClose1 = () => this.setState({ addModalShow1: false });

    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    const {
      devisid,
      datedevis,
      client,
      raisonsociale,
      totalhtbrut,
      remiselignes,
      remiseglobale,
      totalhtnet,
      totaldc,
      totalcos,
      totalttc,
      totaltva,
      droitdetimbre,
      avanceimpot,
      annuler,
      sumqt,
      catfisc,
      taurem,
      valtimbree,
      vendeur,
      regimecli,
      MG,
    } = this.state;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Vente"
              icon={
                <HomeIcon
                  style={{ color: `${darck ? "" : "#fff"}` }}
                  fontSize="small"
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>
          {/* <div>/</div> */}
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // href="/fiche-client"
            label=" Devis Clients"
            icon={<LocalAtmIcon fontSize="small" />}
          />
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
            // href="/fiche-article"
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          />
        </Breadcrumbs>

        <br />
        <Paper
          style={{
            backgroundColor: `${darck ? "" : "#172431"}`,
            border: `${darck ? "" : "2px solid #fff"}`,
          }}
          // elevation={0.5}
          elevation={2}
        >
          <Row style={{display : "flex" , flexWrap: "nowrap" ,marginTop:"5px"}}>
            {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}

            <Col sm={10}>
              <SearchIcon
                className="search-icon"
                color={this.state.isSearchDate ? "inherit" : "primary"}
                style={{ color: `${darck ? "" : "#fff"}` }}
                onClick={() => this.setState({ isSearchDate: false })}
              />
              &nbsp;&nbsp;
              <DateRangeIcon
                className="search-icon"
                color={this.state.isSearchDate ? "primary" : "inherit"}
                onClick={() => this.setState({ isSearchDate: true })}
                style={{ color: `${darck ? "" : "#fff"}` }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.isSearchDate ? (
                <>
                  <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                    Date début
                  </FormLabel>
                  {/* &nbsp;&nbsp; */}
                  <InputBase
                    type="date"
                    // fullWidth={true}
                    placeholder="date"
                    onClick={this.toggle}
                    onChange={this.firstrechdatHandler}
                    value={this.state.firstdate}
                    defaultValue={this.state.defaultdate}
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                    Date Fin
                  </FormLabel>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <InputBase
                    type="date"
                    // fullWidth={true}
                    placeholder="date"
                    defaultValue={this.state.defaultdate}
                    onChange={this.secondrechdatHandler}
                    value={this.state.seconddate}
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />
                </>
              ) : (
                <InputBase
                  margin="dense"
                  // fullWidth={true}
                  placeholder="Rechercher"
                  onChange={this.rechercheHandler}
                  onClick={this.toggle}
                  style={{
                    color: `${darck ? "" : "#fff"}`,
                    backgroundColor: `${darck ? "" : "#172431"}`,
                  }}
                />
              )}
            </Col>

            <Col sm={2} style={{ display: "flex", justifyContent: "end" }}>
              <Button
                color="primary"
                onClick={() => this.setState({ addModalShow1: true })}
                style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}`
              }}
              >
                <AddIcon 
                 style={{
                  color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}`,
                }}/>
                    Ajout Devis
              </Button>
              {/* <InputGroupAddon addonType="append">
              
            </InputGroupAddon> */}
            </Col>
          </Row>
        </Paper>

        <AddDevis show={this.state.addModalShow1} onHide={addModalClose1} />

        <br />

        {/* <div className="bc-table"> */}
        {this.state.rechercheclicked ? (
          <div className="tabdd">
            <table striped>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />

              {/* <thead>
                <tr>
                  <th>№ DV</th>
                  <th>Date</th> */}
              {/* <th style={{ width: "55%" }}>Client</th> */}
              {/* <th>Code client</th>
                  <th style={{ width: "40%" }}>Raison Social</th>
                  <th>Montant </th>
                </tr>
              </thead> */}
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.numfac}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigBCDV?type=DV&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}` 
                      )
                        .then((response) => {
                          response.json();
                        })
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );
                      this.setState({
                        editModalShow: true,
                        devisid: test.numfac,
                        datedevis: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.smNASS,
                        annuler: test.annuler,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.vendeur,
                        regimecli: test.tauxNASS,
                        MG: test.CodDep,
                      });
                      test.timbre
                        ? this.setState({
                            valtimbree: this.props.valtimbres.valtimbres.map(
                              (t) => parseFloat(t.valtimbre)
                            ),
                          })
                        : this.setState({
                            valtimbree: Number(0).toFixed(3),
                          });
                    }}
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numfac}</span>
                    </td>

                    <td>
                      <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    {/* <td style={{ width: "55%" }}>
                      <span>{test.codcli}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>{test.raisoc}</span>
                    </td> */}
                    <td>
                      <span id="code" style={{ color: "#DF5F10" }}>
                        {test.codcli}
                      </span>
                    </td>
                    <td>
                      <span
                        id="raison"
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingLeft: "5px",
                        }}
                      >
                        {test.raisoc}
                      </span>
                    </td>
                    <td>
                      {test.annuler !== "0" ? (
                        <span
                          id="annuler"
                          style={{
                            color: `${darck ? "#182532" : "#fff"}`,
                            paddingLeft: "10px",
                            color: "#DF5F10",
                          }}
                        >
                          Annulé
                        </span>
                      ) : (
                        <span
                          id="annuler"
                          style={{
                            color: `${darck ? "#182532" : "#fff"}`,
                            paddingLeft: "20px",
                          }}
                        >
                          Actif
                        </span>
                      )}
                    </td>
                    <td>
                      <span
                        id="mont"
                        style={{ color: "green", paddingLeft: "10px" }}
                      >
                        {" "}
                        {Number(
                          parseFloat(test.mntbon) 
                          //+ parseFloat(test.valtimbre)
                        ).toFixed(3)}
                      </span>{" "}
                    </td>
                    <td>
                      {" "}
                      <span
                        id="vend"
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingLeft: "45px",
                        }}
                      >
                        {test.vendeur}
                      </span>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

   
   <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Maricule Fiscale : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Devis client</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
             {/* de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
             { moment(this.state.seconddate).format("DD/MM/YYYY")}  */}
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("№ DV")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate(" Date")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Code")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Raison sociale")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Montant")}</th>

               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.state.rechs.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// n.dv ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.numfac}
                
                </td>
 {/* ///////////////////////////////////////////// date ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
          { moment(t.datfac).format("DD/MM/YYYY")} 
                
                </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.codcli}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// raison ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.raisoc}</span>
                   
            </td>
{/* //////////////////////////////////////////////// montant ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
          <span>{Number(t.mntbon).toFixed(3)}</span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "200px" }}>
          
           {/* {this.getTotalQteEntr()} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
           {/* {this.getTotalQtesor()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
           {/* {this.getTotalQteret()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       
         
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
     
          </div>
        ) : this.state.showrechbydate ? (
          <div className="tabdd">
            <table striped>
              {/* <thead>
                <tr>
                  <th>№ DV</th>
                  <th>Date</th> */}
              {/* <th style={{ width: "55%" }}>Client</th> */}
              {/* <th>Code client</th>
                  <th style={{ width: "40%" }}>Raison Social</th>
                  <th>Montant </th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechdats,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.numfac}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigBCDV?type=DV&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );
                      this.setState({
                        editModalShow: true,
                        devisid: test.numfac,
                        datedevis: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.smNASS,
                        annuler: test.annuler,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.vendeur,
                        regimecli: test.tauxNASS,
                        MG: test.CodDep,
                      });
                      test.timbre
                        ? this.setState({
                            valtimbree: this.props.valtimbres.valtimbres.map(
                              (t) => parseFloat(t.valtimbre)
                            ),
                          })
                        : this.setState({
                            valtimbree: Number(0).toFixed(3),
                          });
                    }}
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numfac}</span>
                    </td>

                    <td>
                      <span>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    {/* <td style={{ width: "55%" }}>
                  <span>{test.codcli}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>{test.raisoc}</span>
                </td> */}
                    <td>
                      <span id="code" style={{ color: "#DF5F10" }}>
                        {test.codcli}
                      </span>
                    </td>
                    <td>
                      <span id="raison" style={{ paddingLeft: "15px" }}>
                        {test.raisoc}
                      </span>
                    </td>
                    <td>
                      {test.annuler !== "0" ? (
                        <span
                          id="annuler"
                          style={{
                            paddingLeft: "20px",
                            color: `${darck ? "#DF5F10" : "#fff"}`,
                          }}
                        >
                          Annulé
                        </span>
                      ) : (
                        <span
                          id="annuler"
                          style={{
                            paddingLeft: "20px",
                            color: `${darck ? "#182532" : "#fff"}`,
                          }}
                        >
                          Actif
                        </span>
                      )}
                    </td>
                    <td>
                      <span
                        id="mont"
                        style={{ color: "green", paddingLeft: "30px" }}
                      >
                        {" "}
                        {Number(
                          parseFloat(test.mntbon) 
                          //+ parseFloat(test.valtimbre)
                        ).toFixed(3)}
                      </span>{" "}
                    </td>
                    <td>
                      {" "}
                      <span
                        id="vend"
                        style={{
                          paddingLeft: "45px",
                        }}
                      >
                        {test.vendeur}
                      </span>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            
 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

   
   <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Maricule Fiscale : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Devis client</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
             de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
             { moment(this.state.seconddate).format("DD/MM/YYYY")} 
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("№ DV")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate(" Date")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Code")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Raison sociale")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Montant")}</th>

               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.state.rechdats.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// n.dv ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.numfac}
                
                </td>
 {/* ///////////////////////////////////////////// date ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
          { moment(t.datfac).format("DD/MM/YYYY")} 
                
                </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.codcli}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// raison ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.raisoc}</span>
                   
            </td>
{/* //////////////////////////////////////////////// montant ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
            <span>{Number(t.mntbon).toFixed(3)}</span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "200px" }}>
          
           {/* {this.getTotalQteEntr()} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
           {/* {this.getTotalQtesor()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
           {/* {this.getTotalQteret()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       
         
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
     
          </div>
        ) : (
          <div className="tabdd">
            <table striped>
              {/* <thead>
                <tr>
                  <th>№ DV</th>
                  <th>Date</th> */}
              {/* <th style={{ width: "55%" }}>Client</th> */}
              {/* <th>Code client</th>
                  <th style={{ width: "40%" }}>Raison Sociale</th>
                  <th>Montant </th>
                </tr>
              </thead> */}
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.props.devis.devis,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer", paddingRight: "35px" }}
                    key={test.numfac}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.com/webservice/api/LigBCDV?type=DV&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                          })
                        );

                      this.setState({
                        editModalShow: true,
                        devisid: test.numfac,
                        datedevis: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.smNASS,
                        annuler: test.annuler,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.vendeur,
                        regimecli: test.tauxNASS,
                        MG: test.CodDep,
                      });
                      test.timbre
                        ? this.setState({
                            valtimbree: this.props.valtimbres.valtimbres.map(
                              (t) => parseFloat(t.valtimbre)
                            ),
                          })
                        : this.setState({
                            valtimbree: Number(0).toFixed(3),
                          });
                    }}
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numfac}</span>
                    </td>

                    <td>
                      <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>
                    {/* 
                    <td style={{ width: "55%" }}>
                      <span>{test.codcli}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>{test.raisoc}</span>
                    </td> */}
                    <td>
                      <span id="code" style={{ color: "#DF5F10" }}>
                        {test.codcli}
                      </span>
                    </td>
                    <td>
                      <span
                        id="raison"
                        style={{
                          paddingLeft: "15px",
                          color: `${darck ? "#182532" : "#fff"}`,
                        }}
                      >
                        {test.raisoc}
                      </span>
                    </td>
                    <td>
                      {test.annuler !== "0" ? (
                        <span
                          id="annuler"
                          style={{
                            paddingLeft: "20px",
                            color: `${darck ? "#DF5F10" : "#DF5F10"}`,
                          }}
                        >
                          Annulé
                        </span>
                      ) : (
                        <span
                          id="annuler"
                          style={{
                            paddingLeft: "20px",
                            color: `${darck ? "#182532" : "#fff"}`,
                          }}
                        >
                          Actif
                        </span>
                      )}
                    </td>
                    <td>
                      <span
                        id="mont"
                        style={{ color: "green", paddingLeft: "60px" }}
                      >
                        {" "}
                        {Number(parseFloat(test.mntbon)).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span
                        id="vend"
                        style={{
                          paddingLeft: "45px",
                          color: `${darck ? "#182532" : "#fff"}`,
                        }}
                      >
                        {test.vendeur}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

   
   <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Maricule Fiscale : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Devis client</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("№ DV")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate(" Date")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Code")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Raison sociale")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Montant")}</th>

               
                </tr> 
             
                </thead>
            <tbody>
          
               {this.props.devis.devis.map((t, i) => (

         
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                    height: "30px",
                    border: "1px solid gray",
                 
                  }}>
  {/* ///////////////////////////////////////// n.dv ///////////////////////*/}
            <td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
               {t.numfac}
                
                </td>
 {/* ///////////////////////////////////////////// date ///////////////////*/}
 <td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
               
          { moment(t.datfac).format("DD/MM/YYYY")} 
                
                </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
 <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span>{t.codcli}</span>
                   
            </td>
          
{/* //////////////////////////////////////////////// raison ///////////*/}
             <td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
                        <span >{t.raisoc}</span>
                   
            </td>
{/* //////////////////////////////////////////////// montant ///////////*/}
            <td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
          
            <span>{Number(t.mntbon).toFixed(3)}</span>
     
</td>
                  
                  
                    
                 
                   </tr>  
                  
                  ))} 
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "200px" }}>
          
           {/* {this.getTotalQteEntr()} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
           {/* {this.getTotalQtesor()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
           {/* {this.getTotalQteret()} */}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       
         
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
       
          </div>
        )}


        <br />
   
          <Paper
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // elevation={0.5}
            elevation={3}
          >
            <Row style={{ flexWrap: "nowrap" }}>
              {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}

              <Col sm={9}>
                <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
                 

                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  <label
                   style={{ color: `${darck ? "#0D47A1" : "#fff"}`
                   , fontSize: "12px" }}

                  >T O T A U X : </label>
                </div>
              </Col>
              <Col sm={1} style={{ display: "flex", justifyContent: "end" }}>
              <label>
       
           <span style={{ color: "green" }}>
           {this.getTotalmontant()}
       </span>
     </label>    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                {/* <InputGroupAddon addonType="append">
              
            </InputGroupAddon> */}
              </Col>
            </Row>
          </Paper>

  
        {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}

           
        <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
  
  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
              
        <EditDevisClientModal
          show={this.state.editModalShow}
          onHide={this.editModalClose}
          devisid={devisid}
          datedevis={datedevis}
          client={client}
          regimecli={regimecli}
          raisonsociale={raisonsociale}
          totalhtbrut={totalhtbrut}
          remiselignes={remiselignes}
          remiseglobale={remiseglobale}
          totalhtnet={totalhtnet}
          totaldc={totaldc}
          totalcos={totalcos}
          totalttc={totalttc}
          totaltva={totaltva}
          droitdetimbre={droitdetimbre}
          avanceimpot={avanceimpot}
          vendeur={vendeur}
          cod={devisid}
          annuler={annuler}
          tabtab={this.state.tabtab}
          sumqt={sumqt}
          taurem={taurem}
          catfisc={catfisc}
          valtimbre={this.props.valtimbres.valtimbres.map((t) =>
            parseFloat(t.valtimbre)
          )}
          valtimbree={valtimbree}
          MG={MG}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectUser: (a, b, c, d, e,f,g) =>
      dispatch(SelectUser(a, b, c, d, e, f,g)),
    SelectValTimbre: (a, b, c, d, e) =>
      dispatch(SelectValTimbre(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    devis: state.devis,
    valtimbres: state.valtimbres,
    ConfigDossier: state.ConfigDossier,
    Auth: state.Auth,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DevisClient);

// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "numfac",
    numeric: false,
    disablePadding: true,
    label: "№ DV",
  },

  {
    id: "datfac",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "codcli",
    numeric: true,
    disablePadding: false,
    label: "Code client",
  },

  {
    id: "raisoc",
    numeric: false,
    disablePadding: false,
    label: "Raison sociale",
  },
  { id: "annuler", label: "Etat" },

  {
    id: "mntbon",
    numeric: true,
    disablePadding: false,
    label: "Montant",
  },
  {
    id: "vendeur",
    numeric: true,
    disablePadding: false,
    label: translate("Utilisateur"),
  },
];
function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

import React, { Component } from "react";
import { connect } from "react-redux";
// import "../Commande-client-devis/Styling.css";
import { getClientHeader } from "../../redux/actions/ClientActions";

import "./ClientsFournisseurs.scss";
// import ConnectedSearchBar from "../content/SearchBar";
// import { SelectTest } from "../../redux/actions/TestAction";
import {  Row, Col } from "reactstrap";
// import AddCModal from "./add-modal/AddCModal";
// import GetClientByID from "./GetClientByID";
import GetFournisseurByID from "./GetFournisseurByID";
import AddFmodal from "./add-modal/AddFmodal";
import { Redirect } from "react-router-dom";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  Breadcrumbs,
  InputBase,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import "./style.css";


import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import * as XLSX from 'xlsx';
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import translate from "../../i18n/translate";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import moment from 'moment';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];
 
const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class FicheFournisseur extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      addModalShow: false,
      GetByIdModalShow: false,
      loggedIn,
      rechs: [],
      icon: false,
      defaultdate: date,
      rechercheclicked: false,
      order: "asc",
      orderBy: "",
    };
  }

  toggle = () => this.setState({ modal: !this.state.modal });

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    this.props.getClientHeader();
    // this.props.SelectTest();
    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    document.title = "Fiche Fournisseur";
  }

  rechercheHandler = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/fournisseurs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  
//   exportToExcel = () => {
   
   
//     // Préparation des données
//     const data =  this.props.fournisseurs.fournisseurs.map(item => {
//       const date = new Date(item.datreg);
//       const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
      
//         return {
//           'Code': item.codfrs,
//           'Raison Social': item.raisoc,
//           'Téléphone"': item.tel1,
//           'Adresse': item.adr,
//           'ville':item.ville,
//           'Solde Facture':item.soldfac,
//           'Solde Global':item.soldfacbl,

//             // Ajoutez d'autres champs au besoin
//         };
//     });

//     // Création d'un nouveau classeur
//     const classeur = XLSX.utils.book_new();
//     const feuille = XLSX.utils.json_to_sheet(data);
//     const title = 'Liste des Fournisseurs';
//     feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
//     feuille['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]; // Fusionner les cellules pour le titre
  
//     // Définir l'entête du tableau (deuxième ligne)
//     const header = ['Code', 'Raison Social', 'Téléphone', 'Adresse', 'ville', 'Solde Facture', 'Solde Global'];
//     XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A2' });
// // Ajout de la feuille au classeur
// XLSX.utils.book_append_sheet(classeur, feuille, "FRS");
   
//     // Génération d'un fichier téléchargeable
//     XLSX.writeFile(classeur, "FRS.xlsx");
// }
getTotalsoldfac = () => {
  let totalsoldfac = 0;
  if(this.state.rechercheclicked){
    this.state.rechs.forEach((article) => {
     
      totalsoldfac+= parseFloat(Number(article.soldfac));
  })
}
else{
  this.props.fournisseurs.fournisseurs.forEach((article) => {
    totalsoldfac+= parseFloat(Number(article.soldfac));
  })
};
  return totalsoldfac.toFixed(4); // arrondi à 4 chiffres après la virgule
};
getTotalsoldfacbl = () => {
  let totalsoldfacbl = 0;
  if(this.state.rechercheclicked){
    this.state.rechs.forEach((article) => {
     
      totalsoldfacbl += parseFloat(Number(article.soldfacbl));
  })
}
else{
  this.props.fournisseurs.fournisseurs.forEach((article) => {
    totalsoldfacbl += parseFloat(Number(article.soldfacbl));
  })
};
  return totalsoldfacbl.toFixed(4); // arrondi à 4 chiffres après la virgule
};


exportToExcel = () => {

  if(this.state.rechercheclicked){
    // Préparation des données
  const data = this.state.rechs.map(item => {
    const date = new Date(item.datreg);
    const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
      
    return [
      item.codfrs,
      item.raisoc,
      item.tel1,
      item.adr,
      item.ville,
      item.soldfac,
      item.soldfacbl,
      // Ajoutez d'autres champs au besoin
    ];
  });
// Calcul des totaux
const totals = data.reduce(
  (acc, row) => {
 
    acc[5] += row[5]; // soldfac
    acc[6] += row[6]; // soldfacbl
    return acc;
  },

  ['', '', 'T o t a u x', '', '', 0, 0]
);

// Ajout des totaux à la fin des données
data.push(totals);
  // Création d'un nouveau classeur
  const classeur = XLSX.utils.book_new();
  const feuille = XLSX.utils.aoa_to_sheet(data);

  // Ajout du titre de tableau (première ligne)
  const title = 'Liste des Fournisseurs';
  feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
  feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 6 }  }]; // Fusionner les cellules pour le titre


  // Définition de l'entête du tableau (deuxième ligne)
  const header = ['Code', 'Raison Social', 'Téléphone', 'Adresse', 'Ville', 'Solde Facture', 'Solde Global'];
  XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

  // Ajout des données de tableau à partir de la cinquième ligne
  XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

  const range = XLSX.utils.decode_range(feuille['!ref']);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!feuille[cellRef]) continue;
      feuille[cellRef].s = { alignment: { horizontal: 'center', vertical: 'center' } };
    }
  }



  // Ajout de la feuille au classeur
  XLSX.utils.book_append_sheet(classeur, feuille, "FRS");

  // Génération d'un fichier téléchargeable
  XLSX.writeFile(classeur, "FRS.xlsx");
}
else{
 // Préparation des données
 const data = this.props.fournisseurs.fournisseurs.map(item => {
  const date = new Date(item.datreg);
  const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
    
  return [
    item.codfrs,
    item.raisoc,
    item.tel1,
    item.adr,
    item.ville,
    item.soldfac,
    item.soldfacbl,
    // Ajoutez d'autres champs au besoin
  ];
});
// Calcul des totaux
const totals = data.reduce(
(acc, row) => {

  acc[5] += row[5]; // soldfac
  acc[6] += row[6]; // soldfacbl
  return acc;
},

['', '', 'T o t a u x', '', '', 0, 0]
);

// Ajout des totaux à la fin des données
data.push(totals);
// Création d'un nouveau classeur
const classeur = XLSX.utils.book_new();
const feuille = XLSX.utils.aoa_to_sheet(data);

// Ajout du titre de tableau (première ligne)
const title = 'Liste des Fournisseurs';
feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 6 }  }]; // Fusionner les cellules pour le titre


// Définition de l'entête du tableau (deuxième ligne)
const header = ['Code', 'Raison Social', 'Téléphone', 'Adresse', 'Ville', 'Solde Facture', 'Solde Global'];
XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

// Ajout des données de tableau à partir de la cinquième ligne
XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });

const range = XLSX.utils.decode_range(feuille['!ref']);
for (let R = range.s.r; R <= range.e.r; ++R) {
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = { c: C, r: R };
    const cellRef = XLSX.utils.encode_cell(cellAddress);
    if (!feuille[cellRef]) continue;
    feuille[cellRef].s = { alignment: { horizontal: 'center', vertical: 'center' } };
  }
}



// Ajout de la feuille au classeur
XLSX.utils.book_append_sheet(classeur, feuille, "FRS");

// Génération d'un fichier téléchargeable
XLSX.writeFile(classeur, "FRS.xlsx");
};
  
}

  render() {
    let addModalClose1 = () => this.setState({ addModalShow: false });
    let GetByIdModalClose = () => this.setState({ GetByIdModalShow: false });
    
    const {
      codefournisseur,
      raisoc,
      tel1,
      tel2,
      email,
      adresse,
      siteweb,
      nom,
      ville,
      codepostal,
      identifiant,
      tauxfodec,
      timbre,
      soustraitant,

      acontacter,
      impot,
      comptable,
      soldfac,
      soldfacbl,
    } = this.state;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    const { darck } = this.props;
    return (
      <div>
        <main className="gestion-des-clients">
          <Breadcrumbs aria-label="breadcrumb" fontSize="small">
            <Link to={"/"}>
              <StyledBreadcrumb
                component="a"
                label="Achat"
                icon={
                  <HomeIcon
                    fontSize="small"
                    style={{ color: `${darck ? "" : "#fff"}` }}
                  />
                }
                style={{
                  color: `${darck ? "" : "#fff"}`,
                  backgroundColor: `${darck ? "" : "#172431"}`,
                  border: "2px solid #fff",
                }}
              />
            </Link>
            <StyledBreadcrumb
              component="a"
              color="Primary"
              style={{
                color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
              // href="/fiche-fournisseur"
              label="Fiche Fournisseur"
              icon={<ListAltIcon fontSize="small" />}
            />
             <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
            // href="/fiche-article"
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          />
          </Breadcrumbs>

          <br />
          <Paper
            // elevation={0.5}
            elevation={2}
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
              // height: "37px",
            }}
          >
            <Row style={{display : "flex" , flexWrap: "nowrap",marginTop:"5px"}}>
          
              <Col sm={10}>
                <SearchIcon
                  className="search-icon"
                  color="primary"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;
                <InputBase
                  margin="dense"
                  // fullWidth={true}
                  placeholder="Rechercher"
                  onChange={this.rechercheHandler}
                  onClick={this.toggle}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              </Col>
              <Col sm={2} style={{ display: "flex", justifyContent: "end" }} >
              {/* className="buttfrs" */}
                <Button
                  color="primary"
                  onClick={() => this.setState({ addModalShow: true })}
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                >
                  <AddIcon  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}/>
                  Fiche Fournisseur
                </Button>
              </Col>
            </Row>
          </Paper>
          {/* <br />*/}
  <br /> 
          <div className="tabcff">
            <table striped>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />

              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechercheclicked
                    ? this.state.rechs
                    : this.props.fournisseurs.fournisseurs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.codfrs}
                    onClick={() => {
                      this.setState({
                        GetByIdModalShow: true,
                        codefournisseur: test.codfrs,
                        raisoc: test.raisoc,
                        tel1: test.tel1,
                        tel2: test.tel2,
                        email: test.email,
                        adresse: test.adr,
                        siteweb: test.sitweb,
                        nom: test.nom,
                        ville: test.ville,
                        codepostal: test.cp,
                        identifiant: test.CodeTVA,
                        tauxfodec: test.tauxfodec,
                        timbre: test.timbre,
                        soustraitant: test.STrt,
                        soldfac: test.soldfac,
                        soldfacbl: test.soldfacbl,
                        acontacter: test.fax,
                        impot: test.autreimp,
                        comptable: test.compte,
                      });
                    }}
                  >
                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codfrs}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{`${test.tel1 ? test.tel1 : "--"}/${
                        test.tel2 ? test.tel2 : "--"
                      }`}</span>
                    </td>

                    {test.adr === "" ? (
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>--</span>
                      </td>
                    ) : (
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{test.adr}</span>
                      </td>
                    )}

                    {test.ville === "" ? (
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>--</span>
                      </td>
                    ) : (
                      <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        <span>{test.ville}</span>
                      </td>
                    )}

                    <td>
                      <span style={{ color: "green" }}>
                        {Number(test.soldfac).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "#0D47A1" }}>
                        {Number(test.soldfacbl).toFixed(3)}
                      </span>
                    </td>
                  </tr>
                ))}
                
              </tbody>
            </table>


  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
 

           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "-45px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Maricule Fiscale : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Fiche Fournisseur</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("code")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Raison sociale")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Téléphone")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Adresse")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Ville")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Solde Facture")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Solde Global")}</th>
               
                </tr> 
             
                </thead>
            <tbody>
          {this.state.rechercheclicked
                    ? (<>
            {this.state.rechs.map((t, i) => (

         
<tr
  key={i}
  style={{
    textAlign: "center",
    height: "40px",
    border: "1px solid gray",
 
  }}>
{/* ///////////////////////////////////////// codcli ///////////////////////*/}
<td  style={{ color: `${darck ? "#DF5F10" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 

{t.codfrs}

</td>
{/* ///////////////////////////////////////////// raisoc ///////////////////*/}
{!t.raisoc ? (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          --
        </span>
      </td>
    ) : (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.raisoc}
        </span>
      </td>
    )} 
{/* ////////////////// telephone ///////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
{t.tel2 ? (
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >{`${t.tel1}/${t.tel2}`}</span>
      ) : (
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >{`${t.tel1}/--`}</span>
      )}
</td>

{/* ////////////////// adresse ///////////*/}
{!t.adr ? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          --
        
      </td>
    ) : (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {t.adr}
       
      </td>
    )}
{/* ////////////////// Ville ///////////*/}
{!t.ville ? (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          --
        </span>
      </td>
    ) : (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.ville}
        </span>
      </td>
    )}
{/* //////////////////////////////////////////// soldfac ////////////////////////////*/}
<td style={{ color: `${darck ? "green" : "#fff"}` ,   border: "1px solid gray" }}> {Number(t.soldfac).toFixed(3)}</td>
{/* //////////////////////////////////////////// soldfacbl ////////////////////////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,   border: "1px solid gray" }}>  {Number(t.soldfacbl).toFixed(3)} </td>


  
  
    
 
   </tr>  
  
  ))}</>):(<>
            {this.props.fournisseurs.fournisseurs.map((t, i) => (

         
<tr
  key={i}
  style={{
    textAlign: "center",
    height: "40px",
    border: "1px solid gray",
 
  }}>
{/* ///////////////////////////////////////// codfrs ///////////////////////*/}
<td  style={{ color: `${darck ? "#DF5F10" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 

{t.codfrs}

</td>
{/* ///////////////////////////////////////////// raisoc ///////////////////*/}
{!t.raisoc ? (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          --
        </span>
      </td>
    ) : (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.raisoc}
        </span>
      </td>
    )} 
{/* ////////////////// telephone ///////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 
{t.tel2 ? (
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >{`${t.tel1}/${t.tel2}`}</span>
      ) : (
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >{`${t.tel1}/--`}</span>
      )}
</td>

{/* ////////////////// adresse ///////////*/}
{!t.adr ? (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          --
        
      </td>
    ) : (
      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {t.adr}
       
      </td>
    )}
{/* ////////////////// Ville ///////////*/}
{!t.ville ? (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          --
        </span>
      </td>
    ) : (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.ville}
        </span>
      </td>
    )}
{/* //////////////////////////////////////////// soldfac ////////////////////////////*/}
<td style={{ color: `${darck ? "green" : "#fff"}` ,   border: "1px solid gray" }}> {Number(t.soldfac).toFixed(3)}</td>
{/* //////////////////////////////////////////// soldfacbl ////////////////////////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,   border: "1px solid gray" }}>  {Number(t.soldfacbl).toFixed(3)} </td>


  
  
    
 
   </tr>  
  
  ))}</>)}
          

                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
{/*   <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
              
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col>    */}
     <br></br>  
      <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>
         <span style={{ color: "green" }}>
         <label
          style={{ color: `${darck ? "black" : "#fff"}`
          , fontSize: "14px" }}

         >Solde facture: </label> &nbsp;&nbsp;
         {this.getTotalsoldfac()}
       </span>
     
     </label>    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <br></br>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <label>
         <span style={{ color: "#0D47A1" }}>
         <label
          style={{ color: `${darck ? "black" : "#fff"}`
          , fontSize: "14px" }}

         >Solde Global:
          </label> &nbsp;&nbsp;
         {this.getTotalsoldfacbl()}
       </span>
     
     </label>    
     
  
       
            </h6>
            <br/>    
               
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
                  
          </div>
          
   
   <br />
          <Paper
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // elevation={0.5}
            elevation={3}
          >
            <Row style={{ flexWrap: "nowrap" }}>
              {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}

              <Col sm={10}>
                <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
                 

                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  <label
                   style={{ color: `${darck ? "#0D47A1" : "#fff"}`
                   , fontSize: "12px" }}

                  >T O T A U X : </label>
                </div>
              </Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Col sm={1} style={{ display: "flex", justifyContent: "end" }}>
              <label>
       
           <span style={{ color: "green" }}>
         {this.getTotalsoldfac()}
       </span>
     </label>    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ color: "#0D47A1" }}>
         {this.getTotalsoldfacbl()}
       </span>
     </label>
                {/* <InputGroupAddon addonType="append">
              
            </InputGroupAddon> */}
              </Col>
            </Row>
          </Paper>

          <AddFmodal show={this.state.addModalShow} onHide={addModalClose1} />

          <GetFournisseurByID
            show={this.state.GetByIdModalShow}
            onHide={GetByIdModalClose}
            //  passager={passager}
            codefournisseur={codefournisseur}
            raisoc={raisoc}
            tel1={tel1}
            tel2={tel2}
            email={email}
            adresse={adresse}
            siteweb={siteweb}
            nom={nom}
            ville={ville}
            codepostal={codepostal}
            identifiant={identifiant}
            tauxfodec={tauxfodec}
            timbre={timbre}
            soldfac={soldfac}
            soldfacbl={soldfacbl}
            soustraitant={soustraitant}
            acontacter={acontacter}
            impot={impot}
            comptable={comptable}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DataTables: state.DataTablesReducer,
    // tests: state.tests,
    SearchingResult: state.SearchingReducer,
    fournisseurs: state.fournisseurs,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getClientHeader: () => {
    dispatch(getClientHeader());
  },
  // SelectTest: () => {
  //   dispatch(SelectTest());
  // },
  SelectFournisseur: (a, b, c, d, e) => {
    dispatch(SelectFournisseur(a, b, c, d, e));
  },
});
const ConnectedFicheFournisseur = connect(
  mapStateToProps,
  mapDispatchToProps
)(FicheFournisseur);
export default ConnectedFicheFournisseur;
// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "codfrs",
    numeric: false,
    disablePadding: true,
    label: "Code",
  },

  {
    id: "raisoc",
    numeric: true,
    disablePadding: false,
    label: "Raison sociale",
  },
  { id: "tel1", numeric: false, disablePadding: false, label: "Téléphone" },
  { id: "adresse", numeric: false, disablePadding: false, label: "Adresse" },
  { id: "ville", numeric: false, disablePadding: false, label: "Ville" },


  {
    id: "soldfac",
    numeric: true,
    disablePadding: false,
    label: "Solde Facture",
  },
  {
    id: "soldfacbl",
    numeric: true,
    disablePadding: false,
    label: "Solde Global",
  },
];
function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
